import React from "react";
import AOS from "aos";
import Dots from "shared/Dots";
import AboutIllustration from "assets/imageComponents/aboutIlluestration";
import "aos/dist/aos.css";
import "./index.scss";

const About = () => {
  AOS.init();
  const title = "about";
  return (
    <div className={`${title}-wrapper`} id={title}>
      <div className={title}>
        <div className="under-title">
          {`${title} us`}
          <div className="title">{`${title} us`}</div>
        </div>
        <Dots />
        <div className="content">
          <div className="left-side" data-aos="fade-up">
            <AboutIllustration className="about-illustration" />
          </div>
          <div className="right-side" data-aos="fade-left">
            <p>Welcome to Deep Thought Solutions – Your Trusted Software Development Partner</p>

            <p>Why Choose Us?</p>
            <ul>
              <li>
                <p>Expertise: Skilled developers proficient in Node.js, React.js, Golang, DevOps, and Flutter.</p>
              </li>
              <li>
                <p>Quality: Rigorous QA processes ensure top-notch performance, security, and reliability.</p>
              </li>
              <li>
                <p>Flexibility: Customized engagement models to meet your specific requirements.</p>
              </li>
              <li>
                <p>Timely Delivery: Efficient development process and project management for punctual delivery.</p>
              </li>
              <li>
                <p>Cost-Effectiveness: Competitive pricing without compromising quality.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
