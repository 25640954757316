import React from "react";
import Dots from "shared/Dots";
import AOS from "aos";
import ceberSecurity from "assets/images/cyberSecurity.svg";
import devOps from "assets/images/devOps.svg";
import qa from "assets/images/qa.svg";
import "aos/dist/aos.css";
import "./index.scss";

const CardContent = ({ title, logo, titleHover, text }) => {
  return (
    <>
      <div className="card-content">
        <div className="card-title">{title}</div>
        <div className="card-logo">{logo}</div>
      </div>
      <div className="card-content-hover">
        <div className="card-title">{titleHover}</div>
        <div className="card-text">{text}</div>
        <Dots className="dots" />
      </div>
    </>
  );
};


const Services = () => {
  AOS.init();
  const title = "services";
  return (
    <div className={`${title}-wrapper`} id={title}>
      <div className={title}>
        <div className="under-title">
          {title}
          <div className="title">{title}</div>
        </div>
        <Dots />
        <div className="card-box">
          <div className="card" data-aos="fade-down">
            <CardContent
              title="Custom Software Development"
              logo={<img src={devOps} alt="" className="card-logo-image" />}
              titleHover="Custom Software Development"
              text=" Tailored solutions using Node.js, React.js, Golang, DevOps, and Flutter."
            />
          </div>
          <div className="card" data-aos="zoom-in">
            <CardContent
              title="Full-Stack Development"
              logo={<img src={ceberSecurity} alt="" className="card-logo-image" />}
              titleHover="Full-Stack Development"
              text="Comprehensive and integrated software solutions from front-end to back-end."
            />
          </div>
          <div className="card" data-aos="fade-up">
            <CardContent
              title="API Development and Integration"
              logo={<img src={devOps} alt="" className="card-logo-image" />}
              titleHover="API Development and Integration"
              text="Seamless communication between software systems."
            />
          </div>
          <div className="card" data-aos="fade-up">
            <CardContent
              title="DevOps and Cloud Services"
              logo={<img src={ceberSecurity} alt="" className="card-logo-image" />}
              titleHover="DevOps and Cloud Services"
              text="Streamlined development, deployment, and cloud infrastructure."
            />
          </div>
          <div className="card" data-aos="fade-up">
            <CardContent
              title="Mobile App Development"
              logo={<img src={devOps} alt="" className="card-logo-image" />}
              titleHover="Mobile App Development"
              text="Cross-platform mobile applications with Flutter."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
