import React from "react";
import { Tabs } from "@mui/material";
import Dots from "shared/Dots";
import AOS from "aos";
import ProjctsIllustration from "assets/imageComponents/projectsIllustration";
import "aos/dist/aos.css";
import "./index.scss";

const Cards = ({ image, name, link }) => {
  return (
    <div
      className="tab-content"
      onClick={() => {
        if (link) {
          const aElement = document.getElementById(name);
          aElement.click();
        }
      }}
    >
      <div className="side-l">
        <img className="side-l-image" src={image} alt="" />
      </div>
      <div className="side-r">{name}</div>
      <a id={name} href={link}>
        {" "}
      </a>
    </div>
  );
};

const Services = () => {
  AOS.init();
  const title = "projects";

  return (
    <div className={`${title}-wrapper`} id={title}>
      <div className={title}>
        <div className="under-title">
          {title}
          <div className="title">{title}</div>
        </div>
        <Dots />
        <div className="content">
          <div className="left-side">
            <Tabs
              data-aos="fade-right"
              TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
              className="tabs"
              value={0}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              orientation="vertical"
            >
              <Cards image="/projectsLogos/Artelize.svg" name="Artelize" link="https://artelize.com/" />
              <Cards image="/projectsLogos/completeCase.svg" name="Completecase" link="https://www.completecase.com/" />
              <Cards image="/projectsLogos/bulbee.jpg" name="Bulbee" link="" />
            </Tabs>
          </div>
          <div className="right-side" data-aos="fade-up">
            <ProjctsIllustration className="projects-illustration" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
