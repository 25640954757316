import React from "react";
import Dots from "shared/Dots";
import AOS from "aos";
import ContactsIllustartion from "assets/imageComponents/contactsIllustration";
import "aos/dist/aos.css";
import "./index.scss";

const Services = () => {
  AOS.init();
  const title = "contacts";
  return (
    <div className={`${title}-wrapper`} id={title}>
      <div className={title}>
        <div className="under-title">
          {title}
          <div className="title">{title}</div>
        </div>
        <Dots />
        <div className="content">
          <div className="left-side" data-aos="fade-up">
            <ContactsIllustartion className="contacts-illustration" />
          </div>
          <div className="right-side" data-aos="fade-left">
            <p>
              Experience the power of Deep Thought Solutions for your software development needs. Contact us now to get started.
            </p>
            <b>vlad.poliakov@dtsltd.org</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
